import './App.css'
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom'
import { Login } from "./components/loginPage"
import { Signup } from "./components/signup"
import { LandingPage } from './components/landingPage'
import FileList from './components/filesList'
import { useEffect, useState } from "react"
import { auth } from "./firebase"
import { onAuthStateChanged } from "firebase/auth"
import { AuthWindow } from './components/authWindow'
import MainLayout from './layouts/Mainlayout'
import Dashboard from './components/dashboard/Dashboard'
import Connection from './components/connections/Connection'
import AddConnection from './components/connections/AddConnection'
import Files from './components/Files/Files'

function App() {
  const [user, setUser] = useState(null)
  const [loading, setLoading] = useState(true) // Loading state

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser)
      setLoading(false) // Set loading to false once we have the auth state
    })

    return () => unsubscribe()
  }, [])

  if (loading) {
    // You can return a loading spinner or placeholder here
    return (
    <div>
      <div className="flex items-center justify-center h-screen">
      <img src="./src/assets/loader.gif" alt="Loading" className="h-16 w-16 mr-4" />
      </div>
    </div>)
  }

  return (
    // <Router>
    //   <Routes>
       
    //     <Route path="/login" element={user ? <Navigate to="/dashboard" /> : <Login />} />
    //     <Route path="/signup" element={user ? <Navigate to="/dashboard" /> : <Signup />} />
    //     <Route path="/dashboard" element={user ? <Dashboard_02 /> : <Navigate to="/login" />} />
    //     <Route path="/auth/callback" element={<AuthWindow />}> </Route>
    //     <Route path="/*" element={<LandingPage />} />
    //   </Routes>
    // </Router>

    <Router>
    <MainLayout>
      <Routes>
        <Route path="/login" element={user ? <Dashboard /> : <Login />  } />
        <Route path="/Dashboard" element={user ? <Dashboard /> : <Navigate to="/login" />  } />
        <Route path="/connections" element={<Connection />} />
        <Route path="/addConnections" element={<AddConnection />} />
        <Route path="/Files" element={<Files />} />
      </Routes>
    </MainLayout>
  </Router>
  )
}

export default App
